const initialState = {
    news : [],
    articles : [],
    gallery : [],
    ratings : [],
    single_news : {},
    single_article : {},
    education : [],
    single_education : {},
    video : []
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_NEWS":
        return {
          ...state,
          news : action.payload,
        };
      case "GET_ARTICLES":
        return {
          ...state,
          articles : action.payload,
        };
      case "GET_EDUCATION":
        return {
          ...state,
          education : action.payload,
        };
      case "GET_RATING":
        return {
          ...state,
          ratings : action.payload,
        };
      case "GET_GALLERY":
        return {
          ...state,
          gallery : action.payload,
        };
      case "GET_VIDEO":
        return {
          ...state,
          video : action.payload,
        };
      case "GET_SINGLE_NEWS":
        return {
          ...state,
          single_news : action.payload,
        };
      case "GET_SINGLE_EDUCATION":
        return {
          ...state,
          single_education : action.payload,
        };
      case "GET_SINGLE_ARTICLE":
        return {
          ...state,
          single_article : action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  