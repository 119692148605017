

const cutLetter = ({ letter, maxSize }) => {
    if (letter?.length > maxSize) {
      return `${letter?.slice(0, maxSize)}...`
    } else {
      return letter
    }
  }

export {
    cutLetter
}