/* eslint-disable no-unused-vars */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import VideoPopup from '../../component/video';
import { base } from '../../store/api';
import Loading from '../../component/Loading';
import { bilimMerkezAPI } from '../../api';

function Video() {
  const [news, setNews] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  // const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true)
  const [activeId, setActiveId] = useState(undefined)
  // const [urlSrc, setUrlSrc] = useState("")
  const [videoLoading, setVideoLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const lang = language === 'tk' ? 'tm' : language
    setLoading(true)
    bilimMerkezAPI.get(`/video?limit=1000&lang=${lang}`)
      .then(async (req) => {
        const res = req.data
        setNews(res.results)
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.error(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])


  return (
    <>
      <div className="site-content">
        <PageHeader
          title={t("video")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <Col sm={12}>
                  <div className="blog-wrapper grid-wrapper grid-lg-3 grid-md-2 grid-sm-1">
                    {news.length ? news.map((img, index) => {
                      return (
                        <VideoPopup
                          key={index}
                          videoLoading={videoLoading}
                          videoId={img.id}
                          setActiveId={setActiveId}
                          activeId={activeId}
                          videoURL={`${img.video.src}`}
                          videoImage={`${img.video.poster_thumbnail}`}
                          videoTitle={img.title}
                        />
                      )
                    }) : ''}
                  </div>

                </Col>
              }
            </Row>

          </Container>
        </div>
      </div>
    </>
  );
}

export default Video;
