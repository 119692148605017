import React, { useEffect } from 'react';
import './button.scss'
import gsap from 'gsap';
import { Elastic } from 'gsap';

const DownloadButton = () => {
    useEffect(() => {
        document.querySelectorAll('.button').forEach(button => {

            let duration = 3000,
                svg = button.querySelector('svg'),
                svgPath = new Proxy({
                    y: null,
                    smoothing: null
                }, {
                    set(target, key, value) {
                        target[key] = value;
                        if (target.y !== null && target.smoothing !== null) {
                            svg.innerHTML = getPath(target.y, target.smoothing, null);
                        }
                        return true;
                    },
                    get(target, key) {
                        return target[key];
                    }
                });

            button.style.setProperty('--duration', duration);

            svgPath.y = 20;
            svgPath.smoothing = 0;

            button.addEventListener('click', e => {

                e.preventDefault();

                if (!button.classList.contains('loading')) {

                    button.classList.add('loading');

                    gsap.to(svgPath, {
                        smoothing: .3,
                        duration: duration * .065 / 1000
                    });

                    gsap.to(svgPath, {
                        y: 12,
                        duration: duration * .265 / 1000,
                        delay: duration * .065 / 1000,
                        ease: Elastic.easeOut.config(1.12, .4)
                    });

                    setTimeout(() => {
                        svg.innerHTML = getPath(0, 0, [
                            [3, 14],
                            [8, 19],
                            [21, 6]
                        ]);
                    }, duration / 2);

                }

            });

        });

        function getPoint(point, i, a, smoothing) {
            let cp = (current, previous, next, reverse) => {
                let p = previous || current,
                    n = next || current,
                    o = {
                        length: Math.sqrt(Math.pow(n[0] - p[0], 2) + Math.pow(n[1] - p[1], 2)),
                        angle: Math.atan2(n[1] - p[1], n[0] - p[0])
                    },
                    angle = o.angle + (reverse ? Math.PI : 0),
                    length = o.length * smoothing;
                return [current[0] + Math.cos(angle) * length, current[1] + Math.sin(angle) * length];
            },
                cps = cp(a[i - 1], a[i - 2], point, false),
                cpe = cp(point, a[i - 1], a[i + 1], true);
            return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`;
        }

        function getPath(update, smoothing, pointsNew) {
            let points = pointsNew ? pointsNew : [
                [4, 12],
                [12, update],
                [20, 12]
            ],
                d = points.reduce((acc, point, i, a) => i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${getPoint(point, i, a, smoothing)}`, '');
            return `<path d="${d}" />`;
        }
    }, [])


    return (
        <>
            <div className="container">



                <div className="button white-single">
                    <div>
                        <svg viewBox="0 0 24 24"></svg>
                    </div>
                </div>


            </div>

            <a rel="noreferrer" class="dribbble" href="https://dribbble.com/shots/7299868-Download-Buttons" target="_blank"><img src="https://cdn.dribbble.com/assets/dribbble-ball-mark-2bd45f09c2fb58dbbfb44766d5d1d07c5a12972d602ef8b32204d28fa3dda554.svg " alt="" /></a>

        </>
    )
}

export default DownloadButton