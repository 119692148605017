import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import './style.scss'

function Indicators() {
  const {t} = useTranslation()


  const pricingPlan = [
    {
      id:1,
      title : t("ind"),
      price:t("indicators_1"),
      btn:t("connect"),
      icon: '/images/report_1.svg',
      to: 'https://reyting.it.net.tm'
  },
  {
      id:2,
      title : t("rep"),
      price: t("indicators_2"),
      btn:t("connect"),
      icon: '/images/report_2.svg', 
      to: 'https://yomhil.it.net.tm'
  },
  {
      id:3,
      title : t("rep"),
      price: t("indicators_3"),
      btn:t("connect"),
      icon: '/images/report_3.svg',
      to: 'https://ohomhil.it.net.tm'
  }
  ]

  

  return (
    <>
      <div className="site-content">
        <PageHeader
          title={t("indicators")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
  

          <section className="membership-area pt-110 pb-80">
         <div className="container">
            <div className="row justify-content-center">
               <div className="tab-content" id="priceTabContent">
                  <div className="tab-pane fade active show" id="monthly" role="tabpanel" aria-labelledby="monthly-tab">
                     <div className="row">
                        {
                            pricingPlan.slice(0,3).map((item)=>(
                                <div key={item.id} className="col-xl-4 col-lg-6 col-md-6 my-4">
                                <div className="membership-box mb-30">
                                   <div className="membership-info">
                                       <h4>{item.title}</h4>
                                      <div className="membership-price font-jost d-flex justify-contect-between align-items-center ">
                                         <span>{item?.price === "Free" ? "Free": `${item.price}`}</span>
                                         <img alt='' src={item.icon}/>
                                      </div>
                                      
                                   </div>
                                   
                                   <Link to={item.to} className="membership-btn">{item.btn}</Link>
                                   {
                                    item.popularClassWrapper && <div className={item.popularClassWrapper && item.popularClassWrapper}>
                                    <span>{item.plan && item.plan}</span>
                                 </div> 
                                   }
                                </div>
                             </div>
                            ))
                        }
                     </div>
                  </div>
                  <div className="tab-pane fade" id="yearly" role="tabpanel" aria-labelledby="yearly-tab">
                     <div className="row">
                     {
                            pricingPlan.slice(4,8).map((item)=>(
                                <div key={item.id} className="col-xl-3 col-lg-6 col-md-6">
                                <div className="membership-box mb-30">
                                   <div className="membership-info">
                                      <h4>{item.title}</h4>
                                      <div className="membership-price">
                                         <span>{item?.price === "Free" ? "Free": `${item.price}.00`}</span>
                                         <p>{item.desc}</p>
                                      </div>
                                      <div className="membership-list">
                                         <ul>                                    {
                                            item?.pricingList?.map((pricingItem) => (
                                                <li key={pricingItem.id}>
                                                <i className={pricingItem.icon}></i>
                                                {pricingItem.listInfo}
                                                </li>
                                            ))
                                            }
                                         </ul>
                                      </div>
                                   </div>
                                   <Link href="/contact" className="membership-btn">{item.btn}</Link>
                                   {
                                    item.popularClassWrapper && <div className={item.popularClassWrapper && item.popularClassWrapper}>
                                    <span>{item.plan && item.plan}</span>
                                 </div> 
                                   }
                                </div>
                             </div>
                            ))
                        }
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
        </div>
      </div>
    </>
  );
}

export default Indicators;
