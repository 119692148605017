import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import BlogSidebar from './sidebar-digital';
import './blog.scss'

function DigitalSingle() {
  // Single Post Dynaic ID
  let { name } = useParams();
  const {t} = useTranslation()

  useEffect(() => {
    window.scrollTo(0,0)
  },[name])
  
  const data = [
    {
      id : 1,
      name : t("e_school_digital"),
      src:'/images/e_school.jpeg',
      content: t("school_desc")
    },
    {
      id : 2,
      name : t("e_garden_digital"),
      src:'/images/e_garden.jpeg',
      content: t("garden_desc")
    },
    {
      id : 3,
      name : t("confess"),
      src:'/images/confess.jpg',
      content: ''
    },
    {
      id : 4,
      name : t("online_application"),
      src:'/images/online_applications.jpg',
      content : ''
    },
    {
      id : 5,
      name : t("system_digital"),
      src:'/images/innonesil.jpeg',
      content : ''
    },
    {
      id : 6,
      name : t("payment_digital"),
      src:'/images/payment.jpeg',
      content : ''
    },
  ]
  const index = name === 'e-school' ? 0 : name === 'e-garden' ? 1 : name === 'confess' ? 2 :
  name === 'online_applications' ? 3 : name=== 'system' ? 4 : 5


  return (
    <>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("digital_services")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              <Col xl={8} lg={12}>
                <article className="about-post blog-post">
                <div className='entry-content'>
                  <h4 className="entry-title pt-2">{data[index].name}</h4>
                  </div>
                  <div className="post-image">
                    <img className="w-100" src={data[index].src} alt="" />
                  </div>
                  
                  <div className="entry-content">
                    <div className="entry-description">
                      {
                        name === 'payment' &&
                        <>
                          <p>Elektron kitaphana – bu ýurdumyzyň raýatlaryna dürli tematikadaky kitaplary we okuw kitaplary, mekdep gollanmalary onlaýn görnüşde okap bilmek mümkinçiligini üpjün etmäge niýetlenilýän onlaýn platforma.</p>
                        </>
                      }
                      {
                        name === 'e-school' &&
                        <>
                          <p>Elektron mekdep ulgamy bilim ulgamy bilen tehnologiýany bir nokada getirip, mekdep we ata-eneler bilen bilelikde okuwçynyň başarnygyny açmak.</p>
                          <p>Häzirki zaman bilim ulgamyna degişli bolan, halkara derejedäki soňky innowasion täzelikleri, bilim almagyň innowasion usullarynyň beýanyny, bilim edaralarynyň bilim platformalaryna we saýtlaryna bir penjireden ýüzlenmegini üpjün etmek, şeýle-de ýurdumyzyň bilim ulgamynda alnyp barylýan özgerişler baradaky maglumatlary özünde jemleýän bitewi bilim portalynyň funksiýasyny ýerine ýetirmelidir.</p>
                          <p>Ulgamynyň işlenip düzülmeginiň maksady - Türkmenistanyň Prezidentiniň 2017-nji ýylyň 15-nji sentýabrynda çykaran 340-njy Karary bilen tassyklanylan “Türkmenistanda sanly bilim ulgamyny ösdürmegiň Konsepsiýasy we ony durmuşa geçirmegiň Meýilnamasyna”, şeýle-de 2021-nji ýylyň 12-nji fewralynda çykaran 2136-njy Karary bilen tassyklanylan “Türkmenistanda sanly ykdysadyýeti ösdürmegiň 2021-2025-nji ýyllar üçin Döwlet maksatnamasy we ony amala aşyrmak boýunça ýerine ýetirilmeli çäreleriň Meýilnamasyna” laýyklykda bilim ulgamynyň sanlylaşdyrmagyny kämilleşdirmek.</p>
                          <p>Elektron mekdep ulgamynyň esasy maksady –  bilim ulgamy bilen tehnologiýany bir nokada getirip,  mekdep we ata-eneler bilen bilelikde her bir okuwçyny okuwa höweslendirmek we başarnygyny açmak.</p>
                          <p>Elektron mekdep ulgamynyň esasy niýetlenilen ulanyjylary:</p>
                          <ol>
                            <li>Müdir, administratorlar;</li>
                            <li>Okuwçylar;</li>
                            <li>Mugallymlar;</li>
                            <li>Ene-atalar;</li>
                            <li>Bilim edaralaryň hünärmenleri.</li>
                          </ol>
                        </>
                      }
                      {
                        name === 'e-garden' &&
                        <>
                          <p>Elektron çagalar bagy ulgamynyň esasy wezipeleriniň biri, häzirki zaman innowasion tehnologiýalary ulanmak arkaly, çagalar bagynyň müdirleriniň, terbiýeçileriniň işlerini, hem-de ene-atalarynyň öz perzentleriniň çagalar baglarynda geçirýän wagtlary baradaky has anyk maglumatlar bilen üpjün edilişlerini has-da kämilleşdirmek bolup durýar. Elektron çagalar bagy ulgamynyň üsti bilen ene-atalar öz çagalarynyň çagalar bagy üçin töleg pullaryny geçirmek bilen bilelikde, olaryň gatnaşyklaryny, ders meýilnamalaryny, hepdelik gün tertiplerini we iýmit meýilnamalaryny görüp bilerler. Bu ulgamyň işe girizilmegi arkaly ýurdumyzyň bilim ulgamynda alnyp barylýan kämilleşdirmeler baradaky syýasatdan ugur alýan bitewi bilim portalynyň funksiýasyny ýerine ýetirmelidir.</p>
                          <p>Mekdebe çenli çagalar bagy edaralaryň dolandyryş ulgamynyň esasy niýetlenilen ulanyjylary:</p>
                          <ol>
                            <li>Müdir, administratorlar;</li>
                            <li>Terbiýeçiler;</li>
                            <li>Ene-atalar;</li>
                            <li>Çagalar bagy edaralaryň hünärmenleri.</li>
                          </ol>
                        </>
                      }
                      {
                        name === 'system' &&
                        <>
                          <p>Edaranyň sanly hyzmatlaryny - onuň Ulanyjylara düşnükli, ulanyşly, sada interfeýsli, peýdaly we maglumatlara we hyzmatlara baý bolmagyny üpjün etmek üçin, şeýle hem Ulgamy dolandyrmak, kämilleşdirmek we mydama döwrebaplaşdyrmak üçin ähli    Bilim platformalaryny “Inno Nesil” ulgamyna merkezleşdirmek.</p>
                          
                        </>
                      }
                    </div>
                  </div>

                  {/* <VideoPopup
                    videoURL="https://www.youtube.com/embed/LgvseYYhqU0"
                    videoImage={videoImg}
                  /> */}
                </article>
              </Col>
              <Col xl={4} lg={8} md={8}>
                <BlogSidebar news_id={data[index].id}/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default DigitalSingle;
