export const formatMonth = (month, language) => {
        if(language === 'en') 
        switch (month-1){
          case 0 : return 'January';
          case 1 : return 'February';
          case 2 : return 'March';
          case 3 : return 'April';
          case 4 : return 'May';
          case 5 : return 'June';
          case 6 : return 'July';
          case 7 : return 'August';
          case 8 : return 'September';
          case 9 : return 'October';
          case 10 : return 'November';
          case 11 : return 'Dec';
          default : return "January"
        }
        if(language === 'ru') 
        switch (month-1){
          case 0 : return 'янв';
          case 1 : return 'фев';
          case 2 : return 'мар';
          case 3 : return 'апр';
          case 4 : return 'май';
          case 5 : return 'июнь';
          case 6 : return 'июль';
          case 7 : return 'авг';
          case 8 : return 'сен';
          case 9 : return 'окт';
          case 10 : return 'ноя';
          case 11 : return 'дек';
          default : return "January"
        }
        if(language === 'tm') 
        switch (month-1){
          case 0 : return 'ýan';
          case 1 : return 'few';
          case 2 : return 'mart';
          case 3 : return 'apr';
          case 4 : return 'maý';
          case 5 : return 'iýun';
          case 6 : return 'iýul';
          case 7 : return 'awg';
          case 8 : return 'sen';
          case 9 : return 'okt';
          case 10 : return 'noý';
          case 11 : return 'dek';
          default : return "January"
        }
        if(language === 'tk') 
        switch (month-1){
          case 0 : return 'ýan';
          case 1 : return 'few';
          case 2 : return 'mart';
          case 3 : return 'apr';
          case 4 : return 'maý';
          case 5 : return 'iýun';
          case 6 : return 'iýul';
          case 7 : return 'awg';
          case 8 : return 'sen';
          case 9 : return 'okt';
          case 10 : return 'noý';
          case 11 : return 'dek';
          default : return "January"
        }
}