/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment/moment';
import './blog.scss';
import { formatMonth } from '../../data/format-month';
import Slider from './slider';
import replaceWithSpace from '../../data/replace';
import { HiOutlineEye } from 'react-icons/hi2';
import AboutUsSidebar from '../about-us/sidebar';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { base } from '../../store/api';
import { Navigation, A11y, Autoplay } from 'swiper/modules';
import Loading from '../../component/Loading';
import { cutLetter } from '../../utils';
import { bilimMerkezAPI } from '../../api';

function ArticleSingle() {
  let { id } = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const language = localStorage.getItem('i18nextLng');
  const [latestArticles, setLatestArticles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const formatDate = (date) => {
    const month = date?.split('T')[0].split('-')[1];
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const lang = language === 'tk' ? 'tm' : language;
    setLoading(true);
    bilimMerkezAPI
      .get(`/article/${id}?lang=${lang}`)
      .then(async (req) => {
        const res = req.data;
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [language, id]);

  React.useEffect(() => {
    let params = {
      limit: 20,
      offset: (1 - 1) * 20,
    };
    const lang = language === 'tk' ? 'tm' : language;
    bilimMerkezAPI
      .get(
        `/article?offset=${params.offset}&limit=${params.limit}&lang=${lang}`
      )
      .then(async (req) => {
        const res = req.data;
        setLatestArticles(res.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [language]);

  return (
    <>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t('articles')}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[{ label: t('home'), path: '/' }]}
        />
        <div className="content-wrapper page-template">
          <div>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Loading style={{ width: '10%' }} />
              </div>
            ) : (
              <Container>
                <Row className="justify-content-center">
                  <Col xl={8} lg={12}>
                    <article className="about-post blog-post">
                      <div className="entry-content">
                        <div className="entry-meta">
                          <ul>
                            <li key="meta-category">
                              <img
                                src="/images/calendar.svg"
                                className=""
                                alt="calendar"
                              />
                            </li>
                            <li key="meta-date" className="entry-meta-date">
                              <div>{formatDate(data.published_date)}</div>
                            </li>
                            <li>
                              <div>
                                <HiOutlineEye size={22} />{' '}
                                {data.view_count ? data.view_count : data.views}
                              </div>
                            </li>
                          </ul>
                        </div>
                        <h4 className="entry-title pt-2">
                          {Object.keys(data).length ? data.title : ''}
                        </h4>
                      </div>
                      <div className="post-image">
                        <Slider image={data.image_thumbnail} />
                      </div>
                      <div className="entry-content">
                        <div className="entry-description">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: Object.keys(data).length
                                ? replaceWithSpace(data.content)
                                : '',
                            }}
                          ></div>
                        </div>
                      </div>

                      {data.source_link ? (
                        <div
                          className="entry-button"
                          style={{ width: 250, marginTop: 40, float: 'right' }}
                        >
                          <Link
                            target="_blank"
                            to={data.source_link}
                            className="entry-button-link font-rubik"
                          >
                            <span>{t('source')}</span>
                          </Link>
                        </div>
                      ) : null}
                    </article>
                  </Col>
                  <Col xl={4} lg={8} md={8}>
                    {/* <BlogSidebar news_id={id} /> */}
                    <AboutUsSidebar />
                  </Col>
                </Row>
                <div style={{ margin: '3em 0' }}>
                  <p style={{ fontSize: '24px', fontWeight: '600' }}>
                    {t('readAlso')}:
                  </p>
                  <Swiper
                    loop
                    navigation
                    modules={[Navigation, Autoplay, A11y]}
                    autoplay={{
                      delay: 7000,
                    }}
                    breakpoints={{
                      432: {
                        spaceBetween: 50,
                        slidesPerView: 1,
                      },
                      520: {
                        spaceBetween: 50,
                        slidesPerView: 2,
                      },
                      900: {
                        spaceBetween: 50,
                        slidesPerView: 3,
                      },
                    }}
                    className="mySwiper"
                  >
                    {latestArticles.length
                      ? latestArticles.map((article, i) => {
                          return (
                            <>
                              {String(article.id) !== id && (
                                <SwiperSlide>
                                  <div className="news-post blog-elements">
                                    <Link to={`/article-details/${article.id}`}>
                                      <div
                                        style={{
                                          overflow: 'hidden',
                                          height: '250px',
                                        }}
                                      >
                                        <img
                                          src={`${article.image_thumbnail}`}
                                          alt={article.title}
                                        />
                                      </div>
                                    </Link>
                                    <ul
                                      style={{
                                        listStyle: 'none',
                                        margin: '1em 0',
                                        height: '100px',
                                      }}
                                    >
                                      <li>
                                        {formatDate(article.published_date)}
                                      </li>
                                      <li
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: '600',
                                        }}
                                      >
                                        <Link
                                          to={`/article-details/${article.id}`}
                                        >
                                          {cutLetter({
                                            letter: article.title,
                                            maxSize: 100,
                                          })}
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </SwiperSlide>
                              )}
                            </>
                          );
                        })
                      : ''}
                  </Swiper>
                </div>
              </Container>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ArticleSingle;
