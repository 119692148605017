import { get } from "../middlewares"

export const getNews = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/news?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_NEWS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getSlider = (dispatch, params, lang, callback) => {
    
    dispatch(
        get({
            url: `/${lang}/api/slider`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getCompetitons = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/competitions?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getArticles = (dispatch, params, lang, callback) =>{
    const {limit, offset, } = params
    dispatch(
        get({
            url: `/${lang}/api/articles?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_ARTICLES', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getInstitutions = (dispatch, params, lang, callback) =>{
    const {limit, offset, category_id} = params
    dispatch(
        get({
            url: `/${lang}/api/institutioncategory/${category_id}?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getInstitutionCategories = (dispatch, params, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/institutioncategory`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getRatings = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/rating?limit=${limit||20}&offset=${offset||0}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_RATING', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getGallery = (dispatch, params, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/gallery`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_GALLERY', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getVideo = (dispatch, params, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/video-gallery`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_VIDEO', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getAbout = (dispatch, params, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/about`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getSingleAbout = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/about/${id}`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleNews = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/news/${id}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SINGLE_NEWS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleCompetition = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/competitions/${id}`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleArticle = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/articles/${id}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SINGLE_ARTICLE', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleInstitution = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/institutions/${id}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SINGLE_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleGallery = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/gallery/${id}`,
            action: (response) => {
                if(response) {
                    // dispatch({type : 'GET_SINGLE_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleVideo = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/video-gallery/${id}`,
            action: (response) => {
                if(response) {
                    // dispatch({type : 'GET_SINGLE_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}