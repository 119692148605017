/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getAbout } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { base } from '../../store/api';
import Loading from '../../component/Loading';

const AboutUsSidebar = (props) => {
    const [about, setAbout] = useState([])
    const { t } = useTranslation()
    const language = localStorage.getItem('i18nextLng')
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        const lang = language === 'tm' ? 'tk' : language
        setLoading(true)
        getAbout(dispatch, {}, lang, (err, res) => {
            if (err) console.log(err);
            setLoading(false)
            setAbout(res?.results)
        })
    }, [language])

    return (
        <div className="sidebar-widget-wrapper">
            <div className="sidebar__widget mb-30">
                <div className="sidebar__widget-head mb-35">
                    <h4 className="sidebar__widget-title">{t("ministry")}</h4>
                </div>
                <div className="sidebar__widget-content">
                    {loading ? 
                    <div style={{display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}}>
                        <Loading style={{width: "30%"}}/>
                    </div>
                    : 
                    <div className="rc__post-wrapper ">
                        {
                            about.length ? about.map((item) => (

                                <Link to={`/about/${item.id}`} className={`rc__post d-flex align-items-center ${props.id === item.id ? 'active_post' : ''}`}>
                                    <div className="rc__thumb mr-20">
                                        <Link to={`/about/${item.id}`}>
                                            {item.image && <img src={`${base}${item.image}`} style={{ width: 'auto', aspectRatio: '1:1' }} alt="img not found" />}
                                        </Link>
                                    </div>
                                    <div className="rc__content">
                                        <div className="rc__title">
                                            <Link to={`/about/${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </div>
                                    </div>
                                </Link>
                            )) : ''
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default AboutUsSidebar;