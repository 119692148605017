import React, { useState } from 'react';
import Ratio from 'react-bootstrap/Ratio';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './video.scss';
import { RiLoader5Fill } from "react-icons/ri";



function VideoPopup(props) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={`mx-2 video-popup ${props.theme || ''}`}>
        <Button
          className="video-btn"
          variant="primary"
          onClick={() => {setShow(true); 
          }}
        >
          {String(props.videoId) === String(props.activeId) ? props.videoLoading ? <RiLoader5Fill color='#000' className='' size={24} /> : <i className="fas fa-play fa-2x"></i> : <i className="fas fa-play fa-2x"></i>}
        </Button>
        {(props?.theme === 'video-style-2') === !props.imageHide || (
          <img
            alt='videoImage'
            className="img-fluid image-link"
            src={props.videoImage}
          />
        )}

      </div>

      {!props.videoLoading && <Modal
        show={show}
        size={props.size || 'md'}
        onHide={() => setShow(false)}
        dialogClassName="pgs-video-popup"
        aria-labelledby="contained-modal-title-vcenter modal-content"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>{props.videoTitle}</p>
          <Ratio aspectRatio="16x9">
            <video width="320" height="240" controls autoPlay>
              <source src={props.videoURL} type="video/mp4" />
            </video>
          </Ratio>
        </Modal.Body>
      </Modal>}
    </>
  );
}

export default VideoPopup;
