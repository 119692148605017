/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../blog/blog.scss'
import PageHeader from '../../layouts/page-header/PageHeader';
import { useDispatch } from 'react-redux';
import Pagination from '../../component/pagination';
import { getCompetitons } from '../../store/actions';
import { base } from '../../store/api';
import Loading from '../../component/Loading';

const Competitions = (props) => {
  const dispatch = useDispatch()
  const [news, setNews] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    let params = {
      limit: 20,
      offset: (currentPage - 1) * 20
    }
    setLoading(true)
    getCompetitons(dispatch, params, lang, (err, res) => {
      if (err) console.log(err);
      setNews(res?.results)
      setLoading(false)
      setCount(res?.count)
    })
  }, [language, currentPage])

  return (
    <>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("competitions")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="event-ara page-template content-wrapper">
          <div className="container">
            {loading ? <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
              <Loading style={{ width: "10%" }} />
            </div> :
              <div className="row">
                <div className="col-sm-12">
                  <div className='grid-wrapper grid-lg-2 grid-md-1'>
                    {news.length ? news.map((item) => (
                      <Link to={`/competition-details/${item.id}`} key={item.id} className="single-item">
                        <div className='single-item-content relative'>
                          <div className="event_date f-left">
                            <div className="event_date_inner">
                              {item.image ? <img className='img' src={`${base}${item.image}`} alt='' /> : ''}
                            </div>
                          </div>
                          <div className="event_info">
                            <h3>
                              {item.title}
                            </h3>
                          </div>
                        </div>
                      </Link>
                    )) : ''}
                  </div>
                </div>
              </div>
            }
          </div>

          <Pagination currentPage={currentPage} count={count} handlePageChange={handlePageChange} />
        </div>
      </div>
    </>
  );
};
export default Competitions;