import axios from "axios";

const bilimMerkezAPI = axios.create({
    baseURL: "https://merkez.bilim.tm/api/v1/centralized",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Centralized": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwdWJsaXNoZXIiOjZ9.UIq_VgOoGpkI0IWJMdCNgvpGXd6MWFfitlLz8MVFoho",
    }
  })


export {
    bilimMerkezAPI,
}