import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './rating.scss'
import PageHeader from '../../layouts/page-header/PageHeader';
import DownloadButton from './button';
import { axiosInstance } from '../../store/api';
import Loading from '../../component/Loading'
import i18next from 'i18next';

const Rating = (props) => {
  const { language } = i18next
  const { t } = useTranslation()
  const [documents, setDocuments] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    (async () => {
      setLoading(true)
      await axiosInstance.get(`${language === "tm" ? "tk" : language}/api/doc`).then(async (req) => {
        const res = await req.data.results
        setLoading(false)
        setDocuments(res)
      }).catch((err) => {
        setLoading(false)
        console.error(err)
      })
    })()
  }, [language])

  const handleClick = (src) => {
    const link = document.createElement("a");
    link.href = src;
    link.download = "document.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("documents")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="event-ara page-template content-wrapper">
          <div className="container">
            <div className="row">
              {loading ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <div className="col-sm-12">
                  <div className='grid-wrapper grid-lg-2 grid-md-1'>
                    {documents.map((item) => (
                      <div key={item.id} className="single-item">
                        <div className='single-item-content relative'>
                          <div className="event_date f-left">
                            <div className="event_date_inner">
                              <img src='/images/pdf.svg' alt='pdf' />
                            </div>
                          </div>
                          <Link to={item.src} target="_blank" className="event_info">
                            <h3>
                              {item.title}
                            </h3>
                          </Link>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(item.src)}>
                          <DownloadButton />
                        </div>
                      </div>
                    ))
                    }
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Rating;