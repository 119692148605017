import { base } from '../../store/api';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function SliderImage(props) {

  return (

    <div className="main-slider  d-flex justify-content-center align-items-center bg-overlay-secondary overlay-30">
      <img src={`${props.image}`} alt='' />
    </div>


  );
}

export default SliderImage;
