import React, { Suspense } from 'react';
import RootRouter from './router';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
const Header = React.lazy(() => import('./layouts/header/Header'));
const FooterStyle02 = React.lazy(() =>
  import('./layouts/footer/footer-style-02')
);
const BackTop = React.lazy(() => import('./component/back-top'));

function App() {
  logEvent(analytics, 'button_click', { button_name: 'start' });

  return (
    <Suspense
      fallback={
        <div className="preloader">
          <img className="" src="/images/preloader.gif" alt="preloader" />
        </div>
      }
    >
      <div className="main-wrapper">
        <Header />
        <RootRouter />
        <FooterStyle02 />
        <BackTop />
      </div>
    </Suspense>
  );
}

export default App;
