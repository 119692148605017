import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BlogSidebar = (props) => {

  const { t } = useTranslation()

  const news = [
    {
      id: 1,
      name: t("e_school_digital"),
      src: '/images/e_school.jpeg',
      content: t("school_desc"),
      to: '/digital/e-school'
    },
    {
      id: 2,
      name: t("e_garden_digital"),
      src: '/images/e_garden.jpeg',
      content: t("garden_desc"),
      to: '/digital/e-garden'
    },
    {
      id: 5,
      name: t("system_digital"),
      src: '/images/innonesil.jpeg',
      content: '',
      to: '/digital/system'
    },
    {
      id: 6,
      name: t("payment_digital"),
      src: '/images/payment.jpeg',
      content: '',
      to: '/digital/payment'
    },
  ]

  return (
    <div className="sidebar-widget-wrapper">
      <div className="sidebar__widget mb-30">
        <div className="sidebar__widget-head mb-35">
          <h4 className="sidebar__widget-title">{t("digital_services")}</h4>
        </div>
        <div className="sidebar__widget-content">
          <div className="rc__post-wrapper">
            {
              news.length ? news.map((item) => (

                <div key={item.id} className="rc__post my-4 d-flex align-items-center">
                  <div className="rc__thumb mr-20">
                    <Link to={`${item.to}`}>
                      <img src={item.src} alt="img not found" />
                    </Link>
                  </div>
                  <div className="rc__content">
                    <h6 className="rc__title">
                      <Link to={`${item.to}`}>
                        {item.name}
                      </Link>
                    </h6>
                  </div>
                </div>
              )) : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;