import React from 'react'
import './index.scss'

const Pagination = (props) => {

  let pageNumbers = [];
  for (let i = 1; i <= Math.ceil(props.count / 20); i++)
    pageNumbers.push(i);

  // eslint-disable-next-line array-callback-return
  let renderPageNumbers = pageNumbers.map((e) => {
    if (e === 1 || Math.ceil(props.count / 20) === e || (e > props.currentPage - 3 && e < props.currentPage + 3))
      return (
        <li
          key={e}
          className={"loader-page-item" + (props.currentPage === e ? " active" : "")}
          onClick={() => props.handlePageChange(e)}
        >
          <span>{e}</span>
        </li>
      )

    if (e === props.currentPage - 3 || e === props.currentPage + 3)
      return " ... "
  })

  return (
    <div className="pagination justify-content-center ">

      <div className="col-12 col-sm-6 d-flex justify-content-center">
        <ol className="loader-page swipe-to-top">
          <li
            className="loader-page-item"
            onClick={() => props.currentPage > 1 && props.handlePageChange(props.currentPage - 1)}
          >
            <span><i className="fa fa-angle-double-left" style={{ fontSize: 12 }} /></span>
          </li>
          {renderPageNumbers}
          <li
            className="loader-page-item"
            onClick={() => props.currentPage < Math.ceil(props.count / 20) && props.handlePageChange(props.currentPage + 1)}
          >
            <span><i className="fa fa-angle-double-right" style={{ fontSize: 12 }} /></span>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Pagination