import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAUhXTESCXTX1ln-Rwsa1qhw5zH3d0cTPY",
    authDomain: "edu-gov-tm.firebaseapp.com",
    projectId: "edu-gov-tm",
    storageBucket: "edu-gov-tm.appspot.com",
    messagingSenderId: "932496995992",
    appId: "1:932496995992:web:f712c60a3239e4cf3c82ed",
    measurementId: "G-1VXSSVC0VV"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };
